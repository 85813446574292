/* eslint-disable jsx-a11y/no-static-element-interactions -- no alternative */
/* eslint-disable jsx-a11y/click-events-have-key-events -- no alternative */
import {clsx} from 'clsx'
import type {JSX} from 'react'
import React, {useEffect, useState, useCallback} from 'react'
import {pushToDataLayer} from '@/utils/analytics'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import styles from '@/_new-code/products/flexible-web-toolkit/components/share-block/ShareBlock.module.css'

interface ShareBlockProps {
	title: string
	className?: string
	showOnHover?: boolean
	facebookShareUrl: string
	twitterShareUrl: string
	linkedinShareUrl: string
	mailSubject: string
	mailBody: string
	mailLink: string
	mailLinkToggle?: string
	ellipseColor?: string
	ellipseHoverColor?: string
	sharetitle?: string
	iconSize?: 'sm' | 'md'
	hasAuthor?: boolean
	tabIndex?: number | undefined
	variant?: string
}

export const ShareBlock = ({
	title,
	className,
	showOnHover,
	facebookShareUrl,
	twitterShareUrl,
	linkedinShareUrl,
	mailSubject,
	mailBody,
	mailLink,
	mailLinkToggle = 'no',
	ellipseColor,
	ellipseHoverColor,
	sharetitle,
	iconSize = 'sm',
	hasAuthor,
}: ShareBlockProps): JSX.Element => {
	const [container, setContainer] = useState<Element | null>(null)

	const applyFocusEvent = useCallback(() => {
		if (container) {
			container.classList.add('focus')
		}
	}, [container])

	const applyBlurEvent = useCallback(() => {
		if (container) {
			container.classList.remove('focus')
		}
	}, [container])

	const copyMailLinkToClipboard = (text: string): void => {
		void navigator.clipboard.writeText(text)
	}
	const shareBlockDatalayer = (method: string): void => {
		pushToDataLayer({
			event: 'share',
			method,
			url: window.location.href,
		})
	}

	useEffect(() => {
		setContainer(document.querySelector(`.${styles.shareBlockShowOnHover}`))
		const links = document.querySelectorAll(
			`.${styles.shareBlockShowOnHover} .js-social-link`
		)
		links.forEach((link) => {
			link.addEventListener('focus', applyFocusEvent)
			link.addEventListener('blur', applyBlurEvent)
		})

		return () => {
			links.forEach((link) => {
				link.removeEventListener('focus', applyFocusEvent)
				link.removeEventListener('blur', applyBlurEvent)
			})
		}
	}, [applyFocusEvent, applyBlurEvent])

	const hasTitle = title.length > 0
	const showShareBlock =
		facebookShareUrl.length > 0 ||
		twitterShareUrl.length > 0 ||
		linkedinShareUrl.length > 0 ||
		mailSubject.length > 0 ||
		mailLinkToggle === 'yes'

	let shareIconClassSize = ''
	const marginClass = hasTitle ? 'mr-0' : 'mr-10'
	const alignClass = hasAuthor ? '' : 'justify-end'

	if (iconSize === 'md' && styles.mediumIconSize) {
		shareIconClassSize = styles.mediumIconSize
	}

	return (
		<>
			{showShareBlock ? (
				<div className={clsx(className)}>
					<div
						className={
							showOnHover
								? `${
										styles.shareBlockShowOnHover
									} ${shareIconClassSize} ${
										!hasTitle ? styles.noText : ''
									}`
								: `flex items-center ${alignClass}`
						}
					>
						<p
							className={`text-md ${
								styles.shareBlockShowOnHover
									? marginClass
									: 'mr-2'
							}`}
						>
							{title || 'Share on'}
						</p>
						<ul className="flex p-0 text-primary">
							{showOnHover ? (
								<li className="shrink-0">
									<EllipseButton
										className={`mr-2 ${ellipseColor} hover:${ellipseHoverColor} pointer-events-none`}
										href=""
										icon={
											<svg
												height="24.957"
												viewBox="0 0 21.837 24.957"
												width="21.837"
											>
												<path
													d="M17.158,15.6a4.659,4.659,0,0,0-2.914,1.019l-5-3.122a4.706,4.706,0,0,0,0-2.032l5-3.122A4.671,4.671,0,1,0,12.589,5.7l-5,3.122a4.679,4.679,0,1,0,0,7.322l5,3.122A4.68,4.68,0,1,0,17.158,15.6Z"
													fill="#fff"
												/>
											</svg>
										}
										iconSize="h-6 w-6"
										title="share"
									>
										Share
									</EllipseButton>
								</li>
							) : null}

							{facebookShareUrl.length > 0 && (
								<li className="mr-1 shrink-0">
									<a
										className="js-social-link"
										href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=${facebookShareUrl}&display=popup&ref=plugin&src=share_button`}
										onClick={() => {
											shareBlockDatalayer('Facebook')
										}}
										rel="noreferrer"
										target="_blank"
										title="Share on Facebook"
									>
										<svg
											className="m-auto h-5 fill-current"
											height="32.748"
											viewBox="0 0 15.115 32.748"
											width="15.115"
										>
											<path
												d="M60.73,87.464H57.161c-1.26,0-1.523.516-1.523,1.819v3.149H60.73l-.49,5.528h-4.6v16.514H49.044V98.031H45.615v-5.6h3.429V88.025c0-4.137,2.213-6.3,7.119-6.3H60.73v5.738Z"
												transform="translate(-45.615 -81.727)"
											/>
										</svg>
									</a>
								</li>
							)}

							{twitterShareUrl.length > 0 && (
								<li className="mx-1 shrink-0">
									<a
										className="js-social-link"
										href={`https://twitter.com/share?url=${twitterShareUrl}`}
										onClick={() => {
											shareBlockDatalayer('Twitter')
										}}
										rel="noreferrer"
										target="_blank"
										title="Share on Twitter"
									>
										<svg
											className="m-auto h-5 fill-current"
											height="24.612"
											viewBox="0 0 1200 1227"
											width="30.285"
										>
											<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" />
										</svg>
									</a>
								</li>
							)}

							{linkedinShareUrl.length > 0 && (
								<li className="mx-1 shrink-0">
									<a
										className="js-social-link"
										href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkedinShareUrl}`}
										onClick={() => {
											shareBlockDatalayer('LinkedIn')
										}}
										rel="noreferrer"
										target="_blank"
										title="Share on LinkedIn"
									>
										<svg
											className="m-auto h-5 fill-current"
											height="26.464"
											viewBox="0 0 26.452 26.464"
											width="26.452"
										>
											<path
												d="M421.615,96.922h5.461v17.643h-5.461Zm2.7-2.31a3.255,3.255,0,1,0-3.229-3.255A3.243,3.243,0,0,0,424.319,94.612ZM435.806,105.3c0-2.481,1.142-3.958,3.327-3.958,2.009,0,2.974,1.417,2.974,3.958v9.261h5.435V103.394c0-4.725-2.678-7.01-6.419-7.01a6.153,6.153,0,0,0-5.317,2.915V96.922h-5.238v17.643h5.238Z"
												transform="translate(-421.09 -88.101)"
											/>
										</svg>
									</a>
								</li>
							)}

							{mailLinkToggle !== 'no' && (
								<li className="ml-2 shrink-0 cursor-pointer">
									<span
										className="js-social-link"
										onClick={() => {
											copyMailLinkToClipboard(mailLink)
										}}
										title={sharetitle || 'share via URL'}
									>
										<svg
											className="m-auto h-5 fill-current"
											height="50.32"
											viewBox="0 0 44.03 50.32"
											width="44.03"
										>
											<path d="M34.6,31.44c-2.14-0.01-4.21,0.72-5.88,2.05l-10.07-6.3c0.3-1.35,0.3-2.75,0-4.1l10.07-6.3c4.07,3.24,10,2.56,13.23-1.51s2.56-10-1.51-13.23s-10-2.56-13.23,1.51c-1.77,2.22-2.44,5.13-1.82,7.9l-10.07,6.3c-4.08-3.25-10.01-2.57-13.26,1.51s-2.57,10.01,1.51,13.26c3.44,2.74,8.31,2.74,11.75,0l10.07,6.3c-1.14,5.09,2.07,10.13,7.15,11.27c5.09,1.14,10.13-2.07,11.27-7.15c1.14-5.09-2.07-10.13-7.15-11.27C35.98,31.52,35.29,31.44,34.6,31.44z" />
										</svg>
									</span>
								</li>
							)}

							{mailSubject.length > 0 && (
								<li className="ml-2 shrink-0">
									<a
										className="js-social-link"
										href={`mailto:?subject=${mailSubject}&body=${mailBody}`}
										onClick={() => {
											shareBlockDatalayer('Email')
										}}
										rel="noreferrer"
										target="_blank"
										title="Share via Email"
									>
										<svg
											className="m-auto h-5 fill-current"
											viewBox="0 0 1792 1792"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z" />
										</svg>
									</a>
								</li>
							)}
						</ul>
					</div>
				</div>
			) : null}
		</>
	)
}
