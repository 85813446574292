import {useContext, useEffect, type JSX} from 'react'
import {ArticleLister} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {clsx} from 'clsx'
import {MetaBlock} from '@/_new-code/products/flexible-web-toolkit/components/meta-block'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {GlobalContext} from '@/_new-code/services/global-client-state'
import {pushToDataLayer} from '@/utils/analytics'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {
	ArticleConfigContentItem,
	Block,
} from '@/_new-code/services/kontent-ai/types'

export type FeaturedArticleContentItem = IContentItem<{
	bodyMetaText: Elements.TextElement
	buttonText: Elements.TextElement
	config: Elements.LinkedItemsElement<ArticleConfigContentItem>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const FeaturedArticleBlock: Block<FeaturedArticleContentItem> = ({
	block,
}) => {
	const {locale = ''} = useRouter()
	const gs = useContext(GlobalContext)

	const chosenSpacing = block.elements.snippetSpacingSpacing[0]?.codename
	const componentSpacing = Object.keys(spacing).includes(chosenSpacing ?? '')
		? spacing[chosenSpacing as unknown as keyof typeof spacing]
		: spacing.none
	const config = block.elements.config[0]?.elements

	// TODO: We really need a better system for constructing slugs that does
	// slash de-duping and url encoding and locale prefixing if present
	let linkHref = '#'
	if (config?.articleSlug && config.articleSlug !== '') {
		linkHref = config.articleSlug.startsWith('/')
			? config.articleSlug
			: `/${config.articleSlug}`

		linkHref = locale !== '' ? `${locale}${linkHref}` : linkHref
	}

	// We stick article ID in shared state as so listers know not to display it
	useEffect(() => {
		gs?.[1]({
			...gs[0],
			featuredArticle: block.system.id,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps -- intentional to prevent infintie re-render
	}, [])

	return (
		<div>
			<ArticleLister
				bodyMetaBlock={
					<MetaBlock
						blur={false}
						image={<StarInCircle />}
						metaText=""
					>
						{block.elements.bodyMetaText}
					</MetaBlock>
				}
				className={clsx(
					`container-narrow !shadow-around`,
					componentSpacing
				)}
				description={config?.description}
				imageMetablock={
					config?.articleListerCategory[0]?.name ? (
						<MetaBlock blur={false} metaText={''}>
							{config.articleListerCategory[0]?.name}
						</MetaBlock>
					) : null
				}
				imageUrl={config?.thumbnailImage[0]?.url}
				subtitle=""
				title={config?.title}
			>
				{/* TODO: you can't have a link with a button inside it which also has a click handler the events conflict with one another */}
				<Link href={linkHref} legacyBehavior passHref>
					<Button
						onClick={() => {
							pushToDataLayer({
								event: 'cta_click',
								cta_name: block.elements.buttonText,
								cta_category: 'CTA_Button',
							})
						}}
					>
						{block.elements.buttonText}
					</Button>
				</Link>
			</ArticleLister>
		</div>
	)
}

function StarInCircle(): JSX.Element {
	return (
		<svg
			className="fill-current"
			height="32"
			viewBox="0 0 32 32"
			width="32"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15.983 32.002a16.007 16.007 0 1 1 11.325-4.687 15.87 15.87 0 0 1-11.325 4.687m.038-11.314 5.275 3.76a.83.83 0 0 0 .451.16.66.66 0 0 0 .4-.172.7.7 0 0 0 .242-.8l-2.074-6.033 5.367-3.852.129-.107a.688.688 0 0 0-.532-1.2h-6.58l-2-5.963a.722.722 0 0 0-1.359 0l-2 5.963H6.716a.72.72 0 0 0-.714.721.4.4 0 0 0 .011.117.69.69 0 0 0 .3.506l5.41 3.814-2.079 6.033a.72.72 0 0 0 .247.8.7.7 0 0 0 .4.172.9.9 0 0 0 .446-.16l5.28-3.76Z" />
		</svg>
	)
}
