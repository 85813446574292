import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Button as CButton} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {pushToDataLayer} from '@/utils/analytics'
import {logEvent} from '@/services/client-logger'
import {spacing} from '../../flexible-web-toolkit/styles'

type MikmakProductIdContentItem = IContentItem<{
	dataEan: Elements.TextElement
}>

export type MikmakButtonContentItem = IContentItem<{
	text: Elements.TextElement
	variant: Elements.MultipleChoiceElement
	productIds: Elements.LinkedItemsElement<MikmakProductIdContentItem>
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const MikMakButtonBlock: Block<MikmakButtonContentItem> = ({block}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice

	const handleOnClick = (): void => {
		pushToDataLayer({
			event: 'cta_click',
			cta_name: block.elements.text,
			cta_category: 'CTA_Button',
		})

		logEvent({
			name: 'MIKMAK_BUTTON_CLICK',
			properties: {
				cta_name: block.elements.text,
				product_ids: block.elements.productIds
					.map((id) => id.elements.dataEan.trim())
					.join(','),
			},
		})
	}
	return (
		<div className={clsx(`text-center`, componentSpacing)}>
			<CButton
				className="swn-tag-wtb-btn mt-2"
				data-eans={block.elements.productIds
					.map((id) => id.elements.dataEan.trim())
					.join(',')}
				onClick={handleOnClick}
				variant={block.elements.variant[0]?.codename ?? 'primary'}
			>
				{block.elements.text}
			</CButton>
		</div>
	)
}
