/* eslint-disable no-nested-ternary -- Disabling for whole file as prettier is conflicting */
import {Pagination} from '@elanco/component-library-v2'
import {useInfiniteQuery} from 'react-query'
import {useRouter} from 'next/router'
import {useState} from 'react'
import type {
	ElementModels,
	Elements,
	IContentItem,
	IContentItemElements,
} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {ArticleListerBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block'
import {pushToDataLayer} from '@/utils/analytics'
import type {
	ArticleConfigContentItem,
	Block,
	CategoryConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {fetchListerItems} from '@/_new-code/services/lister-items/client'
import type {ListerItemsQueryKey} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'
import type {ListerItemsSuccessOutput} from '@/pages/api/v2/lister-items'

/**
 * Long long ago, the three Article Listers lived in paradise.
 *
 * Since then, the three have diverged... at some point,
 * they should be unified once again, but until then...
 *
 * Spaghetti code 🍝
 */

interface SharedArticleListerContentItemElements extends IContentItemElements {
	buttonText: Elements.TextElement
	columns: Elements.NumberElement
	featuredDescription: Elements.TextElement
	featuredTitle: Elements.TextElement
	initialArticleNumber: Elements.NumberElement
	listerDescription: Elements.TextElement
	listerTitle: Elements.TextElement
	showFeatured: Elements.MultipleChoiceElement
	subsequentArticleNumber: Elements.NumberElement
}

interface TaxonomyListerContentItemElements
	extends SharedArticleListerContentItemElements {
	tags: Elements.TaxonomyElement
	usePagination: Elements.MultipleChoiceElement
}

export type TaxonomyListerContentItem =
	IContentItem<TaxonomyListerContentItemElements>

interface PetArticleListerContentItemElements
	extends SharedArticleListerContentItemElements {
	category: Elements.TaxonomyElement

	nextButtonText: Elements.TextElement
	prevButtonText: Elements.TextElement
	titleDescSpacing: Elements.MultipleChoiceElement
	usePagination: Elements.MultipleChoiceElement
}

export type PetDynamicArticleListerContentItem =
	IContentItem<PetArticleListerContentItemElements>

interface VetArticleListerContentItemElements
	extends SharedArticleListerContentItemElements {
	categories: Elements.TaxonomyElement
}

type VetDynamicArticleListerContentItem =
	IContentItem<VetArticleListerContentItemElements>

interface FarmArticleListerContentItemElements
	extends SharedArticleListerContentItemElements {
	category: Elements.TaxonomyElement
}

type FarmDynamicArticleListerContentItem =
	IContentItem<FarmArticleListerContentItemElements>

export const DynamicArticleListerBlock: Block<
	| PetDynamicArticleListerContentItem
	| VetDynamicArticleListerContentItem
	| FarmDynamicArticleListerContentItem
> = ({block, page, ...context}) => {
	const router = useRouter()
	const {page: currentPage} = router.query

	const [pageNumber, setPageNumber] = useState(0)

	const {featuredTitle, buttonText, featuredDescription} = block.elements

	const nextButtonText =
		'nextButtonText' in block.elements && block.elements.nextButtonText
			? (block.elements.nextButtonText as string)
			: 'Next'

	const prevButtonText =
		'prevButtonText' in block.elements && block.elements.prevButtonText
			? (block.elements.prevButtonText as string)
			: 'Prev'

	const subsequentArticlesNumber = block.elements.subsequentArticleNumber ?? 6

	const initialArticlesNumber = block.elements.initialArticleNumber ?? 6

	const featuredColumns = 3
	const showFeaturedFlag = block.elements.showFeatured[0]?.codename !== 'no'

	const pageConfig = page.elements.config[0]
	const color =
		pageConfig && 'snippetCategoryColorCategoryColor' in pageConfig.elements
			? (pageConfig as Tersed<CategoryConfigContentItem>).elements
					.snippetCategoryColorCategoryColor[0]?.codename
			: null

	const backgroundColorClass =
		color && color !== 'none' ? `bg-category-${color}` : ''
	const titleClasses = `  ${
		backgroundColorClass ? `${backgroundColorClass} text-white` : ''
	}`

	// Use correct taxonomy as towers use different codenames
	const filterCategories = (
		'tags' in block.elements
			? block.elements.tags
			: 'category' in block.elements
				? block.elements.category
				: 'categories' in block.elements
					? (block.elements as VetArticleListerContentItemElements)
							.categories
					: []
	) as ElementModels.TaxonomyTerm[]

	const taxonomyName =
		'tags' in block.elements
			? 'tags'
			: 'category' in block.elements
				? 'category'
				: 'categories' in block.elements
					? 'categories'
					: null

	const filters = [
		{
			taxonomy: taxonomyName,
			terms: [filterCategories[0]?.codename],
		},
	]

	const onPageChange = (nextPage: number): void => {
		void router.push({
			pathname: `${router.locale ?? ''}${router.asPath.split('?')[0]}`,
			query: {
				page: nextPage,
			},
		})
	}

	// Some towers don't have the "usePagination" element
	const usePagination =
		'usePagination' in block.elements
			? (
					block.elements
						.usePagination as ElementModels.MultipleChoiceOption[]
				)[0]?.codename === 'yes'
			: false

	const {isLoading, isFetching, fetchNextPage, data} = useInfiniteQuery({
		queryKey: [
			'dynamicArticleLister',
			{
				codename: 'config_article',
				locale: router.locale ?? '',
				filters: JSON.stringify(filters),
				orderBy: 'order_by_date_last_modified',
				hideElementCodename: 'hide_from_lister',
				skip:
					(Number(currentPage?.toString() ?? 1) - 1) *
					initialArticlesNumber,
				limit: initialArticlesNumber,
			},
		] as ListerItemsQueryKey,
		queryFn: (opts) =>
			fetchListerItems<Tersed<ArticleConfigContentItem>>(opts),
		getNextPageParam: () => {
			return pageNumber
		},
		staleTime: 10 * (60 * 1000),
		cacheTime: 15 * (60 * 1000),
	})

	const totalCount = data?.pages[0]?.pagination.totalCount ?? 0

	const titleDescPaddingClasses: Record<string, string> = {
		none: 'pb-0',
		sm: 'pb-8',
		md: 'pb-16',
		lg: 'pb-24',
	}
	const titleDescSpacing =
		'titleDescSpacing' in block.elements
			? (
					block.elements
						.titleDescSpacing as ElementModels.MultipleChoiceOption[]
				)[0]?.codename
			: undefined
	const paddingClass = titleDescSpacing
		? titleDescPaddingClasses[titleDescSpacing] || ''
		: ''

	function getDisplayedItemCount(): number {
		const displayedItems = (pageNumber + 1) * initialArticlesNumber

		return displayedItems > totalCount ? totalCount : displayedItems
	}

	function getHasNextPage(): boolean {
		const displayedItems = getDisplayedItemCount()

		return displayedItems < totalCount
	}

	return (
		<div>
			{showFeaturedFlag && featuredTitle ? (
				<div
					className={clsx(
						`full flex flex-col items-center py-10 text-center`,
						titleClasses
					)}
				>
					<h1 data-kontent-element-codename="featured_title">
						{featuredTitle}
					</h1>
					{featuredDescription ? (
						<h2 data-kontent-element-codename="featured_description">
							{featuredDescription}
						</h2>
					) : null}
				</div>
			) : null}
			<div
				className="mx-auto flex max-w-screen-xl flex-wrap justify-center"
				data-kontent-element-codename={taxonomyName ?? undefined}
			>
				{!isLoading && data
					? data.pages
							.slice(0, 1)
							.map(
								(
									pageResults: ListerItemsSuccessOutput<
										Tersed<ArticleConfigContentItem>
									>
								) => {
									// Split into featured and non-featured if necessary
									const featured = showFeaturedFlag
										? pageResults.items.slice(
												0,
												featuredColumns
											)
										: []

									return featured.map((articleConfig) => {
										const {
											elements: {
												title,
												thumbnailImage,
												articleListerCategory,
												tags,
												articleSlug,
											},
										} = articleConfig
										const articleUrl = router.locale
											? `/${router.locale}${articleSlug}`
											: articleSlug
										const articleTag =
											articleListerCategory[0]?.name ||
											tags[0]?.name ||
											''
										return (
											<div
												className="my-2 flex w-full flex-wrap justify-center sm:w-1/2 md:w-1/3"
												key={articleConfig.system.id}
											>
												<ArticleListerBlockBlock
													block={{
														elements: {
															title,
															subtitle: '',
															url: articleUrl,
															image: thumbnailImage,
															metaBlock: [
																{
																	elements: {
																		title: articleTag,
																		// bgColor:
																		// 	backgroundColorClass,
																		url: articleUrl,
																		openInNewTab:
																			[
																				{
																					codename:
																						'yes',
																					name: 'Yes',
																				},
																			],
																		image: [],
																		horizontalAlignment:
																			[
																				{
																					codename:
																						'left',
																					name: 'Left',
																				},
																			],
																		authenticationGateComponentUserRoles:
																			[],
																	},
																	system: {
																		codename:
																			'',
																		id: '',
																		type: 'meta_block',
																		name: '',
																	},
																},
															],
															authenticationGateComponentUserRoles:
																[],
															ctaButton: [],
															openInNewTab: [],
															productLabelUrl: [],
															snippetCategoryColorCategoryColor:
																[
																	{
																		codename:
																			color ??
																			'',
																		name:
																			color ??
																			'',
																	},
																],
														},
														system: {
															codename: '',
															id: '',
															type: 'article_lister_block',
															name: '',
														},
													}}
													page={page}
													{...context}
												/>
											</div>
										)
									})
								}
							)
					: null}
			</div>
			{isFetching ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialArticlesNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{block.elements.listerTitle ? (
				<div
					className={`full flex flex-col items-center py-10 text-center ${
						showFeaturedFlag ? '' : titleClasses
					} `}
				>
					<h1
						className={paddingClass}
						data-kontent-element-codename="lister_title"
					>
						{block.elements.listerTitle}
					</h1>
					{block.elements.listerDescription ? (
						<p data-kontent-element-codename="lister_description">
							{block.elements.listerDescription}
						</p>
					) : null}
				</div>
			) : null}
			<div className="mx-auto flex max-w-screen-xl flex-wrap justify-center">
				{!isLoading && data
					? data.pages.map(
							(
								pageData: ListerItemsSuccessOutput<
									Tersed<ArticleConfigContentItem>
								>,
								index
							) => {
								// Split into featured and non-featured if necessary
								const articles =
									showFeaturedFlag && index === 0
										? pageData.items.slice(featuredColumns)
										: pageData.items

								return articles.map((articleConfig) => {
									const {
										elements: {
											title,
											thumbnailImage,
											articleListerCategory,
											tags,
											articleSlug,
										},
									} = articleConfig
									const articleUrl = router.locale
										? `/${router.locale}${articleSlug}`
										: articleSlug
									const articleTag =
										articleListerCategory[0]?.name ||
										tags[0]?.name ||
										''
									return (
										<div
											className="my-2 flex w-full flex-wrap justify-center sm:w-1/2 md:w-1/3"
											key={articleConfig.system.id}
										>
											<ArticleListerBlockBlock
												block={{
													elements: {
														title,
														subtitle: '',
														url: articleUrl,
														image: thumbnailImage,
														metaBlock: [
															{
																elements: {
																	title: articleTag,
																	// bgColor:
																	// 	backgroundColorClass,
																	url: articleUrl,
																	openInNewTab:
																		[
																			{
																				codename:
																					'yes',
																				name: 'Yes',
																			},
																		],
																	image: [],
																	horizontalAlignment:
																		[
																			{
																				codename:
																					'left',
																				name: 'Left',
																			},
																		],
																	authenticationGateComponentUserRoles:
																		[],
																},
																system: {
																	codename:
																		'',
																	id: '',
																	type: 'meta_block',
																	name: '',
																},
															},
														],
														authenticationGateComponentUserRoles:
															[],
														ctaButton: [],
														openInNewTab: [],
														productLabelUrl: [],
														snippetCategoryColorCategoryColor:
															[
																{
																	codename:
																		color ??
																		'',
																	name:
																		color ??
																		'',
																},
															],
													},
													system: {
														codename: '',
														id: '',
														type: 'article_lister_block',
														name: '',
													},
												}}
												page={page}
												{...context}
											/>
										</div>
									)
								})
							}
						)
					: null}
			</div>
			{isFetching ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialArticlesNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{usePagination ? (
				<Pagination
					activeIndex={pageNumber + 1}
					amountOfPages={Math.ceil(
						totalCount / initialArticlesNumber
					)}
					nextButtonText={
						<span data-kontent-element-codename="next_button_text">
							{nextButtonText}
						</span>
					}
					onPageChange={onPageChange}
					pagesToShow={3}
					prevButtonText={
						<span data-kontent-element-codename="prev_button_text">
							{prevButtonText}
						</span>
					}
					showLastPage
				/>
			) : (
				buttonText &&
				getHasNextPage() && (
					<div
						className="my-10 text-center"
						data-kontent-element-codename="button_text"
					>
						<Button
							onClick={async () => {
								await fetchNextPage()
								setPageNumber((cur) => cur + 1)
								pushToDataLayer({
									event: 'cta_click',
									cta_name: buttonText,
									cta_category: 'button',
								})
							}}
							type="button"
						>
							{buttonText}
						</Button>
					</div>
				)
			)}
		</div>
	)
}
