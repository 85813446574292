import {clsx} from 'clsx'
import {cloneElement} from 'react'
import type {ReactElement, ReactNode, JSX} from 'react'

const iconSizes = {
	none: 'h-auto w-auto',
	xs: 'h-[35px] w-[35px] border-3',
	sm: 'h-[55px] w-[55px] border-3',
	md: 'h-20 w-20 border-[5px]',
	lg: 'h-[120px] w-[120px] border-[7px]',
}

interface IconProps {
	boldTitle?: boolean
	children: ReactElement<{className?: string}>
	className?: string
	noBackground?: boolean
	noBorder?: boolean
	noShadow?: boolean
	showSquareIcon?: boolean
	size?: keyof typeof iconSizes
	title?: ReactNode
	iconCustomClasses?: string
}

export const Icon = ({
	boldTitle = false,
	children,
	className = '',
	noBackground = false,
	noBorder = false,
	noShadow = false,
	showSquareIcon = false,
	size = 'lg',
	title = '',
	iconCustomClasses,
}: IconProps): JSX.Element => {
	const icon = cloneElement(children, {
		className: children.props.className || 'w-full',
	})

	const iconClasses = clsx(
		'text-theme-highlight flex items-center justify-center overflow-hidden [&>img]:mb-0',
		noBackground ? 'bg-transparent' : 'bg-white',
		noShadow ? null : 'shadow-secondary',
		noBorder ? 'border-none' : 'border-theme-main',
		showSquareIcon ? null : 'rounded-[50%]',
		iconSizes[size],
		iconCustomClasses ?? null
	)

	return (
		<div className={clsx('flex flex-col items-center', className)}>
			<div className={iconClasses}>{icon}</div>
			{title ? (
				<div
					className={clsx(
						'text-main mb-4 mt-6 w-full leading-tight',
						boldTitle ? 'font-bold' : 'font-normal'
					)}
				>
					{title}
				</div>
			) : null}
		</div>
	)
}
