import type {ReactNode} from 'react'
import {Children} from 'react'
import {clsx} from 'clsx'
import Link from 'next/link'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {MainNavBorderColor} from './helper'
import {mainNavBorderColors} from './helper'

interface MainNavProps {
	className?: string
	pinned?: boolean
	brandLogo?: ReactNode | null
	logoHrefUrl?: string
	logoHrefInNewWindow?: boolean
	logo?: ReactNode | null
	mobileLogo?: ReactNode | null
	mainBorderColor?: MainNavBorderColor
	extraLogoContent?: ReactNode
	navItems?: ReactNode | null
	nonNavItems?: ReactNode | null
}

const MainNav: React.FC<MainNavProps> = ({
	className = '',
	pinned = false,
	brandLogo = null,
	logoHrefUrl = 'https://www.elanco.com/',
	logoHrefInNewWindow = false,
	logo = null,
	mobileLogo = null,
	mainBorderColor = 'black',
	extraLogoContent = null,
	nonNavItems = null,
	navItems = null,
}) => {
	const {isMobile, isTablet} = useWindowSize()

	const shouldCollapse =
		pinned && (!(isMobile || isTablet) || Children.count(navItems) === 0)

	const logoToUse = logo ?? (
		<svg
			className="w-16 fill-current text-primary"
			height="31.73"
			viewBox="0 0 637.38 316.17"
			width="64"
		>
			<title>Elanco Logo</title>
		</svg>
	)

	const mobileLogoToUse = mobileLogo ?? (
		<svg
			className="mr-4 w-16 fill-current text-primary"
			height="31.73"
			viewBox="0 0 637.38 316.17"
			width="64"
		>
			<title>Elanco Logo</title>
		</svg>
	)

	return (
		<div
			className={clsx(
				className,
				'bg-main text-black',
				!shouldCollapse &&
					'border-b border-gray-400 lg:border-b-0 lg:pb-0 lg:pt-3',
				'z-100 relative transition-all duration-200 ease-out'
			)}
		>
			<div
				className={clsx(
					'border-b-6 mx-auto mt-2 flex items-center justify-between px-4 pb-2 lg:mt-0 lg:px-8',
					mainNavBorderColors[mainBorderColor]
				)}
			>
				<div className="items-top flex">
					<Link
						href={logoHrefUrl}
						rel="noopener"
						target={logoHrefInNewWindow ? '_blank' : undefined}
					>
						{isMobile || isTablet ? mobileLogoToUse : logoToUse}
					</Link>
					{isMobile ? brandLogo : null}
					{extraLogoContent}
				</div>
				<div className="flex flex-grow justify-end">{nonNavItems}</div>
			</div>
			{navItems}
		</div>
	)
}

export {MainNav}
