import Image from 'next/image'
import {VideoBlock} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {useTrustArcCookieValue} from '@/utils/analytics'
import {Visual} from '@/_new-code/products/flexible-web-toolkit/components/visual/visual'

export type VideoWithRichTextContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	videoId: Elements.TextElement
	image: Elements.AssetsElement
	cookieFallbackImage: Elements.AssetsElement
}>

export const VideoWithRichTextBlock: Block<VideoWithRichTextContentItem> = ({
	block,
}) => {
	const trustArcCookie = useTrustArcCookieValue()
	const isVideoHidden = trustArcCookie === '1:' || trustArcCookie === '0:'

	return (
		<Visual
			image={
				isVideoHidden ? (
					<FallbackImage
						cookieFallbackImage={block.elements.cookieFallbackImage}
					/>
				) : (
					<VideoBlock
						posterFrameImageUrl={block.elements.image[0]?.url}
						videoId={block.elements.videoId}
					/>
				)
			}
			imgPosOnMobileSameAsDesktop
			infoSection={<p className="mb-4">{block.elements.description}</p>}
			title={<h3 className="mb-4">{block.elements.title}</h3>}
		/>
	)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- return type is inferred
const FallbackImage = (props: {cookieFallbackImage: TersedAsset[]}) => {
	return props.cookieFallbackImage[0] ? (
		<Image
			alt={props.cookieFallbackImage[0].description || ''}
			className="mb-4 h-48 w-full flex-shrink-0 object-contain"
			src={props.cookieFallbackImage[0].url}
		/>
	) : (
		<div className="mb-4 h-48 w-full bg-blue-900" />
	)
}
