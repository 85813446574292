import {MetaTags} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import type {JSX} from 'react'
import type {
	ArticleConfigContentItem,
	Block,
	PageConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import type {TaxonomyValue} from '@/_new-code/models/page-props'
import {env} from '@/utils/env/client.mjs'
import {spacingPadding} from '../../styles'

export type TagBlockContentItem = IContentItem<{
	title: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

function getTaxonomyTags(
	pageConfig: Tersed<PageConfigContentItem>
): TaxonomyValue[] {
	if (!('articleListerCategory' in pageConfig.elements)) return []

	const articleConfig = pageConfig as Tersed<ArticleConfigContentItem>

	const out: TaxonomyValue[] = []

	if ('concerns' in articleConfig.elements) {
		out.push(...articleConfig.elements.concerns)
	}

	if (
		'speciesTaxonomy' in articleConfig.elements &&
		env.NEXT_PUBLIC_TOWER !== 'farm'
	) {
		out.push(...articleConfig.elements.speciesTaxonomy)
	}

	if ('tags' in articleConfig.elements) {
		out.push(...articleConfig.elements.tags)
	}

	return out
}

export const TagBlockBlock: Block<TagBlockContentItem> = ({
	block,
	page,
}): JSX.Element => {
	const {locale} = useRouter()

	const pageConfig = page.elements.config[0]
	const taxonomyTags = pageConfig ? getTaxonomyTags(pageConfig) : []

	// TODO: make this less WET (also used in Row)
	const componentSpacing = (() => {
		switch (block.elements.snippetSpacingSpacing[0]?.codename) {
			case 'default': {
				return spacingPadding.default
			}
			case 'top': {
				return spacingPadding.top
			}
			case 'bottom': {
				return spacingPadding.bottom
			}
			case 'none': {
				return spacingPadding.none
			}
			default: {
				return spacingPadding.none
			}
		}
	})()

	const pageConfigType = page.elements.config[0]?.system.type

	return (
		<MetaTags
			as={pageConfigType === 'config_article' ? 'a' : 'p'}
			className={componentSpacing}
			headerText={block.elements.title}
			tagUrlPrefix={
				pageConfigType === 'config_article'
					? `${locale}/taxonomy/term`
					: ''
			}
			tags={taxonomyTags}
			titleAboveTags={Boolean(block.elements.title)}
			titleBorder={Boolean(block.elements.title)}
		/>
	)
}
