import type {VideoBlock} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import type Image from 'next/image'
import type {LargeVisualBlock} from '../../blocks/large-visual-block/large-visual-block'

interface VisualProps {
	className?: string
	reversed?: boolean
	titleBelowImageOnMobile?: boolean
	imgPosOnMobileSameAsDesktop?: boolean
	title?: React.ReactNode
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- Required as video block bringing any as type
	image?: typeof Image | typeof LargeVisualBlock | typeof VideoBlock
	infoSection?: React.ReactNode
	buttonSection?: React.ReactNode
}

export const Visual: React.FC<VisualProps> = ({
	className = '',
	reversed = false,
	titleBelowImageOnMobile = false,
	imgPosOnMobileSameAsDesktop = false,
	title,
	image,
	infoSection,
	buttonSection,
}) => {
	return (
		<div
			className={clsx(
				`relative`,
				`px-6`,
				`py-4`,
				`md:flex`,
				`md:px-0`,
				`md:py-8`,
				{'flex-row-reverse': reversed},
				{className}
			)}
		>
			<div
				className={
					image
						? clsx(`md:w-1/2`, {
								'md:ml-8': reversed,
								'md:mr-8': !reversed,
							})
						: ''
				}
			>
				{!titleBelowImageOnMobile && title}
				{!imgPosOnMobileSameAsDesktop && (
					<div className="mt-6 md:hidden">{image}</div>
				)}
				{titleBelowImageOnMobile ? (
					<div className="mt-6 md:mt-0">{title}</div>
				) : null}
				{infoSection}
				{buttonSection}
			</div>
			<div
				className={clsx(
					`relative`,
					{
						'mt-8 md:mt-0': imgPosOnMobileSameAsDesktop,
						'hidden md:block': !imgPosOnMobileSameAsDesktop,
					},
					{'md:flex-1': image !== null && image !== undefined}
				)}
			>
				{image}
			</div>
		</div>
	)
}
